<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 95.11 43.94"
  >
    <g id="Calque_2"
       data-name="Calque 2"
    >
      <g id="grouper">
        <path class="cls-1"
              d="M22.47,42.17a35.48,35.48,0,0,1,50.17,0"
        /><path class="cls-1"
                d="M12.08,31.78a50.17,50.17,0,0,1,71,0"
        /><path class="cls-1"
                d="M1.77,21.47a64.76,64.76,0,0,1,91.58,0"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: none;
  stroke: #292828;
  stroke-miterlimit: 10;
  stroke-width: 5px;
  fill-rule: evenodd;
}
</style>
